@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.imagePart {
  @include container;
}

.innerBox {
  @include inner-box;
  justify-content: space-between;
}

.textPart {
  width: 50%;
  max-width: 450px;
}

.image {
  width: 50%;
  max-width: 450px;
  height: 450px;
  object-fit: content;
  object-position: top right;
}

.title {
  @include text-subtitle-variant;
}

.text {
  @include text-small-font;
}