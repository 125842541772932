@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.banner {
  @include general-container;
  background-color: $main-color-first;
}

.innerBox {
  @include inner-box-page;
  min-height: 380px;
  color: $text-color-primary;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.twoBlocks {
  @include flex-general;
  justify-content: space-between;
}

.mainInfo {
  @include flex-column;
  justify-content: center;
  max-width: 500px;
  padding: 0 20px 0 0;
}

.imageInfo {
  @include flex-general;
  justify-content: flex-end;
  max-width: 500px;
}

.title {
  @include text-big-title;
  margin: 0 0 50px 0;
}

.subtitle {
  @include text-regular-font;
  margin: 0 0 70px 0;
  line-height: 28px;
}

.button {
  border: 0;
  margin: 0 30px 0 0;
}

.buttonsBlock {
  @include flex-general;
}

.secondBut {
  text-transform: capitalize;
  justify-content: flex-start;
}

.image {
  width: 100%;
  max-width: 520px;
  height: 400px;
  object-fit: contain;
  object-position: bottom right;
}

@media screen and (max-width: 1180px) {
  .banner {
    padding: 0 0 0 $tab-size-padding;
  }
  .mainInfo {
    max-width: 400px;
  }
  .imageInfo {
    max-width: 470px;
  }

  .image {
    max-width: 490px;
    object-fit: cover;
  }
}

@media screen and (max-width: 850px) {
  .image {
    max-width: 400px;
    object-fit: cover;
  }
}

@media screen and (max-width: 690px) {
  .banner {
    padding: 0;
  }

  .title {
    margin: 0 0 30px 0;
  }

  .subtitle {
    margin: 0 0 40px 0;
  }

  .twoBlocks {
    @include flex-column;
  }
  
  .mainInfo {
    max-width: 690px;
    padding: 35px $mobile-size-padding;
  }
  
  .imageInfo {
    max-width: 690px;
  }

  .image {
    max-width: 690px;
    height: 300px;
    object-position: bottom center;
  }
}
