@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.container {
  @include general-container;
}

.innerBox {
  @include inner-box-page;
  min-height: 90vh;

  align-items: center;
}

.form {
  @include flex-column;
  max-width: 450px;
  margin: 40px 0 0 0;
}

.input {
  margin: 0 0 20px 0;
  border-radius: 5px;
  padding: 10px;
  border: 2px solid blueviolet;
}

.input:focus {
  border: 2px solid $bg-accent;
  background-color: rgba(0, 0, 0, 0.09);
  color: blueviolet;
}

.input::placeholder {
}

.button {
  color: white;
  background-color: blueviolet;
  border-radius: 15px;
  padding: 15px 18px;
  border: 0;
  max-width: 180px;
  cursor: pointer;

  &:disabled {
    cursor: default;
    background-color: rgb(185, 181, 181);
  }
}
