@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.firstPreview {
  @include container;
  background-color:  $main-color-next;
}

.innerBox {
  @include inner-box;
  flex-direction: column;
}

.column {
  max-width: 600px;
  width: 100%;
}

.image {
  width: 500px;
  height: 350px;
  object-fit: cover;
}

.textColumn .title {
  @include text-subtitle-variant;
}

.subtitleText {
  @include text-regular-font;
}

.twoBlocks {
  @include flex-general;
  min-height:50px;
  margin: 0 0 40px 0;
  padding: 0;
}

.title {
  @include text-subtitle-variant;
}

.columns {
  list-style: none;
  display: grid;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  grid-template-columns: repeat(4, calc(26.3% - 30px));
  row-gap: 30px;
  column-gap: 20px;
}

.benefitLi {
  @include flex-column;
  max-width: 310px;
  padding: 25px;
  border-radius: $border-radius-default;
  background-color: white;
}

.icon {
  width: 35px;
  height: 35px;
}

.benefitTitle {
  @include text-sub-title-second;
  margin: 20px 0;
}

.benefitsList {  
  @include text-small-font;
  list-style-type: circle;
  margin: 0 0 0 20px;
  padding: 0;
}



@media screen and (max-width: 1180px) {
  .firstPreview {
    padding: 50px $tab-size-padding;
  }
}

@media screen and (max-width: 850px) {
  .mainInfo {
    max-width: 830px;
  }
  .imageInfo {
  max-width: 0;
  }
  .columns {
  grid-template-columns: repeat(2, calc(51% - 30px));
  }
  .benefitImage {
    max-width: 390px;
}
}

@media screen and (max-width: 480px) {
  .twoBlocks {
    flex-direction: column;
  }
  .columns {
    grid-template-columns: repeat(1, 100%);
    }
}