@mixin text-default {
  font-family: 'JetBrains Mono', sans-serif;
  font-weight: 400;
}

@mixin text-big-title {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 64px;
  line-height: 42px;
  color: $text-color-primary;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@mixin text-sub-title {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 48px;
  line-height: 54px;
  color: $text-color-primary;
  box-sizing: border-box;
  margin: 0 0 30px 0;
  padding: 0;
}

@mixin text-subtitle-variant {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 30px;
  line-height: 36px;
  color: $dark-title-color;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  padding: 0;
  font-weight: 600;
}

@mixin text-sub-title-second {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: $text-color-primary;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@mixin mainpage-subtitle-second {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 28px;
  line-height: 30px;
  font-weight: 500;
  color: $dark-title-color;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@mixin dashboard-section-title {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 22px;
  line-height: 24px;
  margin: 0 0 30px 0;
  padding: 0;
  color: $dark-title-color;
  font-weight: 600;
}

@mixin footer-sub-title-second {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: white;
}

@mixin text-regular-font {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: $text-color-primary;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@mixin text-link-font {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

@mixin link-highlighted {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: $main-color-accent;
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

@mixin text-small-font {
  font-family: 'JetBrains Mono', sans-serif;
  font-size: 14px;
  line-height: 26px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@mixin flex-general {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

@mixin flex-column {
  display: flex;
  width: 100%;
  flex-direction: column;  
 box-sizing: border-box;
 justify-content: center;
 align-items: center;
}

@mixin general-container {
  @include flex-column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
@mixin container {
  width: 100%;
  min-height: 460px;
  padding: 60px 0;
  margin: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: flex-start;
}

@mixin inner-box {
  @include flex-general;
  max-width: $container-width;
}

@mixin inner-box-page {
  @include flex-column;
  box-sizing: border-box;
  max-width: $container-width;
  margin: $card-padding 0 0 0;
  padding: 0;
  min-height: 70vh;
}

@mixin title {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 30px;
  line-height: 34px;
}

@mixin link-general {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

@mixin gridElement {
  border-radius: 15px;
  box-sizing: border-box;
  padding: 20px;
}