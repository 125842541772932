@import "/src/utils/ui-library/styles/fontStyles.scss";
@import "/src/utils/ui-library/styles/colors.scss";

$animation_time: 0.4s;

.button {
  @include text-default;
  border: 0;
  outline: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 50px;

  &:focus {
    outline: 0px;
  }
}

.filled_small {
  @include text-small-font;
  color: $text-color-primary;
  background-color: $bg-accent;
  height: 30px;
  padding: 8px 14px;
  max-width: 120px;

  &:hover {
    background-color: $bg-dark;
    transition: background-color $animation_time ease;
    cursor: pointer;
  }
  &:disabled {
    background-color: $bg-disabled;
    cursor: default;
  }
}

.filled_big {
  @include text-regular-font;
  color: $text-color-primary;
  background-color: $bg-accent;
  padding: 16px 20px;
  max-width: 120px;

  &:hover {
    background-color: $bg-dark;
    transition: background-color $animation_time ease;
    cursor: pointer;
  }
  &:disabled {
    background-color: $bg-disabled;
    cursor: default;
  }
}

.only_outline {
  @include text-regular-font;
  color: $text-color-primary;
  background-color: transparent;
  padding: 16px 20px;
  max-width: 140px;
  border: 2px solid $text-color-primary;

  &:hover {
    background-color: $bg-dark;
    transition: background-color $animation_time ease;
    cursor: pointer;
  }
  &:disabled {
    background-color: $bg-disabled;
    cursor: default;
  }
}

.dash_load_more {
  @include text-regular-font;
  color: $text-color-primary;
  background-color: $bg-second;
  padding: 10px 14px;
  font-weight: 500;
  border: 0;
  border-radius: $border-radius-default;
  cursor: pointer;
  transition: background-color $animation_time ease;
  transition: color $animation_time ease;

  &:hover {
    background-color: $bg-dark;
    color: white;
    transition: background-color $animation_time ease;
    transition: color $animation_time ease;
  }
  &:disabled {
    background-color: $bg-disabled;
    cursor: default;
  }
}

.card_answer {
  @include text-regular-font;
  color: $bg-accent;
  background-color: transparent;
  padding: 20px 34px;
  max-width: 80px;
  border: 2px solid $bg-accent;
  &:hover {
    background-color: $main-color-first;
    transition: background-color $animation_time ease;
    color: $text-color-primary;
    cursor: pointer;
  }
}

@mixin card-navigation {
  padding: 16px 26px;
  max-width: 90px;
  cursor: pointer;

  &:disabled {
    cursor: default;
    background-color: rgb(203, 201, 201);
    border: 0;
    color: black;
    &:hover {
      background-color: rgb(203, 201, 201);
      color: black;
    }
  }
}

.card_nav_filled {
  @include card-navigation;
  color: $text-color-primary;
  background-color: $bg-accent;
  border: 0;
  &:hover {
    background-color: $main-color-next;
    transition: background-color $animation_time ease;
    color: $text-color-primary;
  }
}

.card_nav_outline {
  @include card-navigation;
  color: $bg-dark;
  background-color: transparent;
  border: 2px solid $faded-grey;
  &:hover {
    background-color: $main-color-next;
    transition: background-color $animation_time ease;
    color: $text-color-primary;
  }
}

.filled_highlight {
  @include text-regular-font;
  color: white;
  background-color: $main-color-accent;
  border-radius: 27px;
  padding: 16px 20px;
  max-width: 190px;
  font-size: 14px;
  cursor: pointer;
}

.outline_small {
  @include text-small-font;
  color: $bg-second;
  background-color: transparent;
  border: 2px solid $bg-second;
  height: 30px;
  padding: 8px 14px;

  &:hover {
    background-color: $bg-dark;
    transition: background-color $animation_time ease;
    cursor: pointer;
  }
  &:disabled {
    background-color: $bg-disabled;
    cursor: default;
  }
}

.simple_text {
  @include text-small-font;
  font-size: 13px;
  color: rgb(140, 139, 139);
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  padding: 2px;
  margin: 0;
  justify-content: flex-end;
  height: 30px;
  max-width: 30px;

  &:hover {
    text-decoration: underline;
  }
}
