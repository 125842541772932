.container {
  display: flex;
  width: 100%;
  background-color: #e6e6e6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.innerBox {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.message {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 30px;
}

.preview {
  background-color: white;
  padding: 30px;
  border-radius: 30px;
  min-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.preview__title {
  font-family: 'Segoe UI', Verdana, Tahoma;
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  color: black;
  margin: 0 20px 0 0;
  padding: 0;
}

.preview__par {
  font-family: 'Segoe UI', Verdana, Tahoma;
  font-size: 18px;
  line-height: 20px;
  color: black;
}

.preview__img {
  object-fit: cover;
  border: 0;
}
