@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.lessonsBox {
    @include flex-column;
    min-height: 60vh;
    margin: 0;
    padding: 20px;
    border: 1px solid $main-color-first;
  
  }
  
.courses {
    @include flex-column;
    padding: 30px 0 0 0;
  }
  
  .listCourses {
    @include flex-general;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding:0;
    box-sizing: border-box;
  }
  
  