@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.container {
  @include flex-general;
  box-sizing: border-box;
  color: $text-color-contrast;
  justify-content: center;
  background-color: $dark-accent;
  position: relative;
}

.innerBox {
  @include inner-box;
  padding: 30px 0;
}

.footer {
  box-sizing: border-box;
  min-height: 270px;
  justify-content: space-between;
}

.footerColumn {
  width: 100%;
  max-width: 320px;
  @include flex-column;
}

.columnBlock {
  min-height: 80px;
  margin: 0 0 30px 0;
  padding: 0;
}
.columnBlock:last-of-type {
  margin: 0;
}

.menu {
  flex-direction: column;
  margin: 0;
  padding: 0;
  & a {
    color: $text-color-contrast;
  }
}

.link {

  color: $text-color-contrast;
  text-decoration: none;
  padding: 15px 0;
}

.subtitle {
  @include footer-sub-title-second;
  font-size: 18px;
  font-weight: 700;
}

.linksList {
  @include flex-column;
}

.button {
  max-width: 130px;
  padding: 8px 12px;
  font-size: 13px
}

.decoration {
  position: absolute;
  right: 0;
  top: 30px;
  display: block;
  background-color: $main-color-accent;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 50px;
  height: 250px;
}

@media screen and (max-width:650px) {
  
.footer {
  flex-direction: column;
  padding: 20px;
}

  .footerColumn {
    width: 100%;
    max-width: 630px;
    margin: 0 0 20px 0;
  }
}