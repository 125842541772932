@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.howWeWork {
  @include container;
  background-color: $bg-color;
}

.innerBox {
  @include inner-box-page;
  min-height: 380px;
  color: $text-color-primary;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
.twoBlocks {
  @include flex-general;
  justify-content: space-between;
}

.mainInfo {
  @include flex-column;
  justify-content: center;
  max-width: 750px;
  padding: 0;
}

.imageInfo {
  @include flex-general;
  justify-content: flex-end;
  max-width: 500px;
}

.textBlock {
  @include flex-column;
  align-items: flex-start;
  margin: 0 50px 0 0;
}

.title {
  @include text-sub-title;
}

.benefitTitle {
  @include text-sub-title-second;
  margin: 20px 0 10px 0;
  min-height: 60px;
}

.subtitle {
  @include text-small-font;
  margin: 0 0 20px 0;
}

.benefits {
  @include flex-column;
  align-items: flex-start;
}

.benefitsList {
  list-style: none;
  display: grid;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  grid-template-columns: repeat(4, calc(26.3% - 30px));
  row-gap: 30px;
  column-gap: 20px;
}

.benefit {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid blue; */
}

.link {
  justify-content: flex-start;
  align-items: flex-start;
}

.benefitImage {
  width: 100%;
  max-width: 260px;
  height: 180px;
  object-fit: cover;
  object-position: bottom right;
  margin: 0 0 20px 0;
  border-radius: 20px;
}

@media screen and (max-width: 1180px) {
  .howWeWork {
    padding: 50px $tab-size-padding;
  }
}

@media screen and (max-width: 850px) {
  .mainInfo {
    max-width: 830px;
  }
  .imageInfo {
  max-width: 0;
  }
  .benefitsList {
  grid-template-columns: repeat(2, calc(51% - 30px));
  }
  .benefitImage {
    max-width: 390px;
}
}

@media screen and (max-width: 420px) {
  .benefitsList {
    grid-template-columns: repeat(1, 100%);
    }
}