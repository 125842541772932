@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.container {  
  @include general-container;
  width: 100%;
  display: flex;
  min-height: 60vh;
  justify-content: center;
  align-items: center;
}

