@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.additional {
  @include container;
  background-color: $main-color-first;
}

.innerBox {
  @include inner-box-page;
  min-height: 380px;
  color: $text-color-primary;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.title {
  @include text-subtitle-variant;
  margin: 0 0 30px 0;
}

.columns {
  list-style: none;
  display: grid;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  grid-template-columns: repeat(3, calc(33.3% - 30px));
  row-gap: 30px;
  column-gap: 20px;
}

.benefitLi {
  @include flex-column;
  max-width: 310px;
  padding: 25px;
  border-radius: 20px;
  background-color: white;
}

@media screen and (max-width: 850px) {
  .innerBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .columns {
    @include flex-column;
    justify-content: center;
    align-items: center;
  }
}