/* sizes: */
$tab-size-padding: 25px;
$mobile-size-padding: 15px;
$container-width: 1040px;
$border-radius-default: 20px;
$border-radius-small: 5px;
$page-margin-top: 30px;
$card-padding: 30px;

/*colors: */

$main-color-accent: #f58a07;
$accent-faded: #f3a749c8; 
$text-color-primary: #1d3444;
$main-color-first: #dceaf5;
$darker-main-first: #9dc1db;

$main-color-next: #edf7ff;
$dark-accent: #063255;
$dark-title-color: #1d3444;
$bg-color: white;
$bg-second: #e0dcdc;
$bg-accent: #1b8f8f;
$bg-dark: rgb(16, 14, 14);
$bg-disabled: grey;
$text-color-secondary: #010101;
$text-color-contrast: #f1f1f1;
$dashboard-second: #eebdb6;
$dashboard-accent: blueviolet;
$textcolor: #f1f1f1;
$tab-admin-color: rgb(16, 14, 14);
$faded-grey: rgb(222, 222, 222);

$main-dash-accent: #11786a;
$dash-accent-faded: #63c09cc8; 


/* I didn't like orange, so I went with green 
$main-color-accent: #f58a07; bright orange 
$accent-faded: #f3a749c8; faded orange
*/
/*
GREEN SCHEME: 
$bg-color: rgb(16, 5, 26);
$bg-second: #1e8cb0;
$bg-accent: rgb(14, 137, 53);
$bg-dark: rgb(230, 132, 132);
$bg-disabled: grey;
$text-color-primary: green;
$text-color-contrast: #f1f1f1;
$container-width: 1140px; */

$themes: (
  'main': (
    $main-color-accent: #f58a07,
    $text-color-primary: rgb(0, 20, 2),
    $main-color-first: #dceaf5,
  ),
  'violet': (
    $main-color-accent: rgb(98, 56, 171),
    $text-color-primary: rgb(27, 17, 81),
    $main-color-first: #bebbff,
  ),
  'green': (
    $main-color-accent: #00885a,
    $text-color-primary: rgb(107, 50, 6),
    $main-color-first: #1b8f8f,
  ),
);
