@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.box {
  @include inner-box;
  min-height: 60vh;
  flex-direction: column;
}

.withSidebar {
  display: grid;
  grid-template-columns: 78% 22%;
  column-gap: 0;
  box-sizing: border-box;
}

.sidebar {
    width: 100%;
    min-height: 60vh;
    background-color: $main-color-first;
    padding: 30px;
    box-sizing: border-box;
    border-radius: $border-radius-default;
  }
  
  .widget {
    @include flex-column;
  }

  .title {
    @include text-subtitle-variant;
  }