@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.box {
  @include inner-box;
  min-height: 60vh;
  flex-direction: column;
}

.image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
}

.textBox {
    box-sizing: border-box;
    padding: 0;
    margin: 40px 0;
    display: flex;
    justify-content: center;
    width: 100%;

}

.text {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    max-width: 750px;
    opacity: .8;
    line-height: 30px;
}