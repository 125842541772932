@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.container {
  background-color: white;
  width: 100%;
  display: flex;
  min-height: 92px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.innerBox {
  width: 100%;
  max-width: $container-width;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.box {
  @include flex-general;
  justify-content: space-between;
  align-items: center;
}

.box_mobile {
display: none;
}

.header__moreinfo {
  @include flex-general;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  align-items: center;
}

.link {
  color: $dark-title-color;
  text-decoration: none;
}
.logoImage {
  
  width: 120px;
  height: 40px;
  object-fit: contain;

}

.logolink {
  composes: link;
  padding: 0;
  margin: 0 50px 0 0;
  font-weight: 700;
}

.nav__linkAdmin {
  background-color: $bg-accent;
}

.header__par {
  padding: 0;
  margin: 5px 0;
}

.header__span {
  font-size: 12px;
  padding: 0;
  margin: 0 10px 0 5px;
}

.header__bold {
  font-weight: 600;
}

.mobileMenuButton {
  background-color: transparent;
  border: 0;
  padding:0;
  margin: 0;
  cursor: pointer;
}

.burgerImg {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.mobileMenuButton_hidden {
  visibility: hidden;
}


.mobileMenuButton_shown {
  visibility: visible;
}

@media screen and (max-width: 1180px) {
  .container {
    padding: 0 $tab-size-padding;
  }

}


@media screen and (max-width: 690px) {
  .container {
    padding: 0 $mobile-size-padding;
  }
  .innerBox {
    justify-content: space-between;
  }
  .box {
    display: none;
  }
  .box_mobile {
    display: block;
    }
}
