@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.dash__tabs {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  
  .dash__tab {
    padding: 15px;
    cursor: pointer;
    font-size: 17px;
    font-weight: 600;
    background-color: $faded-grey;
    border: 0;
    &_active {
    font-weight: 700;
    background-color: $darker-main-first;
  }
  }