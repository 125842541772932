@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1250px;
  justify-content: space-between;
  margin: 0;
  padding: 0 40px 0 0;
  box-sizing: border-box;
}

.methodcolor {
  background-color: $main-color-next;
}



.link {
  @include link-general;
  color: $bg-dark;
  margin: 20px 0 0 0;
}
