@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.method {
  @include flex-column;
  max-width: 360px;
  margin: 0 0 50px 0;
  background-color: white;
  border-radius: 15px;
}
.methodLink {
  @include flex-column;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.gradientBox {
  padding: 20px; // switch off gradient
  width: 100%;
  min-height: 250px;
  background: linear-gradient(
    90deg,
    rgba(153, 178, 198, 1) 0%,
    rgba(244, 237, 234, 1) 100%
  );
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; 
  box-sizing: border-box;  
}

.image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center center;
  box-sizing: border-box;
  
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.textBlock {
  @include flex-column;
  padding: 32px;
  background-color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.title {
  @include text-sub-title-second;
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 16px 0;
}

.exerpt {
  @include text-regular-font;
  margin: 0 0 24px 0;
  min-height: 40px;
}

.linkText {
  @include link-highlighted;
  text-decoration: none;
}

.links {
  display: flex;
  justify-content: space-between;
}
