@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.nav {
  @include flex-general;
  max-width: 550px;
}

.nav_mobile {
  flex-direction: column;
  width:100%;
  box-sizing: border-box;
  align-items: center;
}

.nav__list {
  list-style: none;
  @include flex-general;
  margin: 0;
  padding: 0;
}

.nav__item {
  @include flex-general;
  margin: 0 20px 0 0;

}

.nav__itemlogged {
  max-width: 80px;
}

.nav__itemnotlogged {
  max-width: 60px;
}

.item_mobile {
  margin: 0;
  width: 100%;
}


.link {
  color: $text-color-primary;
  text-decoration: none;
}

.nav__link {
  composes: link;  
  font-size: 16px;
  padding: 0 10px;
  /* border: 2px solid $border-color-first;*/
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__link:hover {
  background-color: $bg-accent;
  transition: background-color 0.3s ease-in;
}

@media screen and (max-width: 690px) {
  .nav__item {
    max-width: 340px;
  }
}
