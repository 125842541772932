@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.container {
  @include general-container;
}

.innerBox {
  @include inner-box-page;
  min-height: 90vh;

  align-items: center;
}

.form {
  @include flex-column;
  max-width: 450px;
  margin: 40px 0 0 0;
}

.input {
  margin: 0 0 20px 0;
  border-radius: 5px;
  padding: 10px;
  border: 2px solid blueviolet;
}

.input:focus {
  border: 2px solid $bg-accent;
  background-color: rgba(0, 0, 0, 0.09);
  color: blueviolet;
}

.input::placeholder {
}

.button {
  color: white;
  background-color: blueviolet;
  border-radius: 15px;
  padding: 15px 18px;
  border: 0;
  max-width: 180px;
  cursor: pointer;
}

.langs {
  display: flex;
  padding: 0;
  margin: 20px 0;
}

.radio {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  &__label {
    margin: 0 20px 0 0;
    border: 3px solid $bg-accent;
    padding: 10px;
    border-radius: 20px;
    
  cursor: pointer;
  }
  &:checked + .radio__label {
    
    background-color: $bg-accent;
  }
}
