@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.page {
  @include flex-column;
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}