@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.course {
    @include flex-general;
    align-items: center;
    justify-content: center;
    max-width: 305px;
    padding: 0;
    margin: 0 0 30px 0;
  }
  
  .courseLink {
    @include flex-general;
    align-items: center;
    justify-content: center;
    max-width: 305px;
    min-height: 90px;
    font-weight: 700;
    text-decoration: none;
    padding: $card-padding;
    border-radius: $border-radius-default;
    background-color: $main-color-first;
    transition: background-color .4s ease-in;
  
    &:hover {
      background-color: $darker-main-first;
      transition: background-color .4s ease-in;
    }
  
    &__onroll {
      background-color: $darker-main-first;
      color: white;
    }
  }
  
  .courseTitle {
    @include text-regular-font;
    font-weight: 600;
    text-align: center;
  }
  