@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.box {
  @include inner-box;
  min-height: 60vh;
  flex-direction: column;
}

.container {
display: flex;
width: 100%;
box-sizing: border-box;
  justify-content: space-between;
}

.grid {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 15px));
    grid-template-rows: 193px;
    grid-gap: 20px;
    padding: 0px;
    margin: 30px 0;
  }

.start {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
    border:1px solid $bg-color;
    @include gridElement;
    border: 1px solid $faded-grey;
  }
  
  .progress {
    grid-row: 1;
    grid-column: 3 / span 2;
    @include gridElement;
    border: 0;
    border:1px solid $main-color-first;
  }
  
  .starred {
    grid-row: 3 / span 2;
    grid-column: 1;
    @include gridElement;
    border: 0;
    border:1px solid $faded-grey;
  }
  .pronounce {
    min-height: 300px;
    grid-row: 3 / span 2;
    grid-column: 2;
    @include gridElement;
    border: 0;
    border:1px solid $main-color-first;
  }

  .tips {
    grid-row: 2 / span 2;
    grid-column: 3;  
    @include gridElement;
    border: 1px solid $main-color-first;
  }
  
  .warmout {
    grid-row: 2 / span 2;
    grid-column: 4;
    @include gridElement;
    border: 0;
   border: 1px solid $faded-grey;
  }
  
  
  .upload {
    grid-row: 4;
    grid-column: 3 / span 2;
    min-height: 150px;
    @include gridElement;
    border: 1px solid $main-color-first;
  }
  
  
  .listTasks {
    @include flex-column;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 30px 0 0 0;
  }

  .dash {
    @include flex-column;
    margin: 60px 0 0 0;
  }