@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.tabslist {
  list-style-type: none;
  margin: 30px 20px 0 0;
  padding: 0;
  display: flex;
  width: 100%;
  max-width: 170px;
  flex-direction: column;  
  box-sizing: border-box;
}

.itemlist {  
  width: 100%;
  max-width: 170px;
  margin: 0 0 10px 0; 
  padding: 0;
  box-sizing: border-box;
}

.navbut {  
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
  box-sizing: border-box;
  background-color: rgb(241, 222, 241);
  margin: 0;
}

.icon {
  width: 22px;
  height: 22px;
  margin: 0 0 12px 0;
}

.par {
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  padding: 0;
}