@import '/src/utils/ui-library/styles/fontStyles.scss';
@import '/src/utils/ui-library/styles/colors.scss';

.personalinfo {
  @include flex-general;
  justify-content: flex-end;
  align-items: center;  
  &_notLogged {
    padding: 0;    
  max-width: 150px;
  }
  &_logged {
    padding: 0 0 0 20px;
    border-left: 2px solid grey;    
    max-width: 210px;
  }
  
}
.header__par {
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 16px;
  font-size: 90%;

}

.buttonPersThird {
  justify-content: flex-start;
  font-size: 12px;
  height: 30px;
}

.personal {
    color: $text-color-primary;
    text-decoration: none;
}

.loggedInInfo {
  @include flex-column;
  box-sizing: border-box;
}

.loggedEmailInfo {
  @include flex-general;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 80%;
}

.navButtonsLogged {
  @include flex-general;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

@media screen and (max-width: 1180px) {
  .headerPersonal {
  }
}

@media screen and (max-width: 860px) {
  .headerPersonal {  }
}

@media screen and (max-width: 690px) {
  .personalinfo { 
    flex-direction: column;
    max-width: 350px;
    justify-content: flex-start;
   }
   .buttonPersFirst {
    margin: 0;
   }
   .buttonPersSecond {
    color: rgb(205, 205, 205);
   }
}

